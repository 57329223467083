export default [
    {
        question: "Will I have to go to court for my car accident case?",
        answer: "Possibly, but this is unlikely. The vast majority of car accident cases are resolved in the form of an out-of-court settlement negotiated between the involved parties and the insurance company. Generally speaking, your case will only go to trial (meaning you will have to appear in court) if there is a dispute over who is at fault for your accident, there is particularly complex case law that applies to your situation, or if the insurance company does not offer you a settlement amount that fully compensates you for your injuries.",
    },
    {
        question: "How much is my case worth?",
        answer: "Every car accident case is different, therefore it is impossible to put a dollar amount on your case without reviewing your situation in depth with a skilled attorney. Generally speaking, your case's value will be influenced by factors such as the nature and severity of your injuries, the amount of available insurance coverage, and the strength of the evidence proving the other party's fault. Your attorney can give you a rough estimate of the value of your case, but this does not guarantee a specific outcome.",
    },
    {
        question: "Do I still have a case if I was partially responsible for the crash?",
        answer: "Yes, you may still have a valid case even if you were partially at fault for your car accident. New York recognizes pure comparative negligence, meaning the court will determine each driver's percentage of fault and apply that percentage to any damages that are awarded. While some states place limits that restrict a plaintiff from recovering compensation if he or she is less negligent than the defendant, New York allows plaintiffs to recover compensation regardless of their share of fault. In other words, if you are found to be 90% at fault for causing an accident, you may still recover damages for the defendant's 10% of blame, though your recovery will be reduced by 90%.",
    },
    {
        question: "Do I really need a lawyer after a car accident?",
        answer: "If you were injured in the crash to the point where you had to miss work, school, or were otherwise prevented from engaging in your usual activities, it is in your best interests to hire an attorney. While there is no law requiring you to retain legal representation after a collision, the reality of the matter is that you are far more likely to recover the compensation you deserve if you have a skilled legal advocate by your side. An attorney can bring a number of benefits to your case, including thoroughly investigating the circumstances leading up to your car accident, gathering evidence, handling all communication and negotiations with interested parties (including insurance companies), and fiercely representing your interests at trial if necessary.",
    },
    {
        question: "How long will it take to resolve my case?",
        answer: "Every car accident case is different, therefore it is impossible to predict the specific timeline of your case. Car accident cases can vary in length from as little as a few months to as much as a few years depending on factors like the types of injuries involved, the extent of medical care required, the complexity of applicable case law, and whether or not the case goes to trial. Generally speaking, claims involving catastrophic or life-changing injuries such as traumatic brain injuries or spinal cord damage will take much longer to resolve than those involving comparatively minor injuries like whiplash.",
    },
    {
        question: "How much does a car accident lawyer cost?",
        answer: "The last thing you need after being injured in a car accident is to face hefty legal costs during your pursuit of justice. For this reason, most car accident attorneys, including those at Alex Yadgarov &amp; Associates, accept cases on a contingency fee basis. In short, this means that your attorney will cover all upfront costs of taking on your case and will only be paid if they are successful in recovering a settlement or award on your behalf.&nbsp;This ensures your attorney's goals are aligned with yours and allows you to focus on your physical recovery with peace of mind as your case progresses..",
    },
    {
        question: "Should I accept the insurance company's settlement offer?",
        answer: "It is highly advised you do not accept any settlement offered by the insurance company until you have had a chance to consult with an attorney from our firm. Many insurance companies will offer a lowball settlement when you are most vulnerable in an attempt to quickly and cheaply resolve your claim. Our attorneys will be able to negotiate with the insurance company and pursue a settlement that fully compensates you for all past, present, and future losses related to your injuries.",
    },
]