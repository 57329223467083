import React from 'react'
import './guidance.less';
import { Underlines } from '../Underlines/Underlines';

export default () => {
  return (
    <section className="guidance">
        <Underlines/>
        <article className="container guidance-container">
            <div className="guidance-wrapper">
                <h2>
                    FREE Case Guidance, Just 1 Step Away.
                </h2>
                <p>
                    Act Fast! Don’t Miss the Chance to Claim the Recovery You Need for Your Injuries.
                </p>
                <a href="tel:718-276-2800" className='btn'>
                    Get Your FREE Consultation Now 
                    <span>
                        718-276-2800
                    </span>
                </a>
            </div>
        </article>
    </section>
  )
}
