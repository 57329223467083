import React, { useState } from 'react';
import {BsFillShieldLockFill} from 'react-icons/bs'
import {IoIosLock} from 'react-icons/io'
import { Underlines, UnderlineSm } from "../Underlines/Underlines";
import Cta from '../CTA/Cta';
import Clock from '../../../images/Clock.png'
import './next.less'
export default () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [injury, setInjury] = useState("");
    const [message, setMessage] = useState("");

    const logEvent = (e) => {
        console.log(e.currentTarget.id);
    }


    return (
        <section className="next" id="next">
            <Underlines/>
            <article className="next-wrapper container">
                <img src={Clock} alt="Clock" />
                <h2>Your Time May Run Out.</h2>
                <div className="ul-one">
                    <Underlines/>
                </div>
                <p className="yellow-text">
                    Here’s What You Should Do Next
                </p>
                <ul>
                    <li>
                        Contact us now for a FREE consultation and tell us your story.
                    </li>
                    <li>
                        If we think we can help, you will be offered a FREE appointment with the lawyer and if needed we will come to you at 100% ZERO no cost.
                    </li>
                    <li>
                        During your appointment our lawyer will be able to determine if your claim has merit.
                    </li>
                    <li>
                        If so we will offer you our <span>No Win - No Fee</span> arrangement.
                    </li>
                    <li>
                        Sign up and we’ll begin working on your case.
                    </li>
                </ul>
                <div className="ul-two">
                    <Underlines/>
                </div>
                <a href="tel:718-276-2800" className='btn'>
                    Get Your FREE Consultation Now 
                    <span>
                        718-276-2800
                    </span>
                </a>
            </article>
        </section>
    )
}