import React from "react";
import Navbar from "../Navbar/Navbar";
import Consult from "./Consult";
import { UnderlineSm } from "../Underlines/Underlines";
import Alex from "./images/ay-hero.jpeg";
// import CertificateOne from "../../images/certficates/ABA.png"
// import CertificateTwo from "../../images/certficates/Expertise.png"
// import CertificateThree from "../../images/certficates/Lawyer\ of\ distinction.png"
// import CertificateFour from "../../images/certficates/NYSBA.svg"
// import Signature from '../../images/signature 1.png'
import { GiCheckMark } from "react-icons/gi";
import { AiFillStar } from "react-icons/ai";
import Cta from "../CTA/Cta";
import "./hero.less";

export default () => {
  return (
    <main>
      <Navbar />
      <section className="hero-car-accident" id="hero">
        <section className="hero-grid">
          {/* ================ COL 1 ================ */}
          <article className="alex">
            <div className="alex-image-wrapper">
              <img src={Alex} alt="Alex Yadgarov" />
            </div>
          </article>

          {/* ================ COL 3 ================ */}
          <article className="case-eval">
            <div className="case-eval-wrapper">
              <h2>
                {" "}
                <span>Suffered a Car Accident Injury?</span> Get Max
                Compensation.
              </h2>
              <UnderlineSm />
              <p>
                Your Prepared Attorney. Get What You Deserve - Claim Your{" "}
                <span>100% FREE</span> Consult Now. Se habla español. Мы говорим
                по русски.{" "}
              </p>
              <div className="point-container">
                <ul>
                  <li>
                    <span>
                      <GiCheckMark />
                    </span>{" "}
                    Millions Won For Our Injury Clients
                  </li>
                  <li>
                    <span>
                      <GiCheckMark />
                    </span>{" "}
                    FREE Until You Win
                  </li>
                  <li>
                    <span>
                      <GiCheckMark />
                    </span>{" "}
                    Hundreds of 5-Star Reviews
                  </li>
                  <li>
                    <span>
                      <GiCheckMark />
                    </span>{" "}
                    100% NO Fee Unless You Win
                  </li>
                  <li>
                    <span>
                      <GiCheckMark />
                    </span>{" "}
                    5-Star Law Firm
                  </li>
                </ul>
                <a href="tel:718-276-2800" className="btn">
                  Get Your FREE Consultation Now 718-276-2800
                </a>
              </div>
              <div className="review-container">
                <div className="single-review">
                  <p>“Great set of people to work with.”</p>
                  <div>
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                  </div>
                </div>
                <div className="single-review">
                  <p>
                    “The staff really work hard to win your case and give you
                    the best ourcome!!”
                  </p>
                  <div>
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                  </div>
                </div>
                <div className="single-review">
                  <p>“Good quality and very professional service.”</p>
                  <div>
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};
