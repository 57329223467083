import React from 'react'
import CarAccident from '../../../images/car-accidents.jpg'
import './car-accident.less'
export default () => {
  return (
    <section className="car-accident" id='car'>
        <article className="container">
            {/* ATTORNER */}
            <div className="attorney-container">
                <div className="attorney-description">
                    <h2>Rosedale Car Accident Attorneys</h2>
                    <h4>
                        Representing Car Accident Victims in Queens and all of New York
                    </h4>
                    <p>
                        Our attorneys at Alex Yadgarov & Associates know how painful auto accident injuries can be. After you’ve gone through the stress of getting treated for your symptoms, you may also be wondering how to recover any lost items, or whether you may be entitled to compensation. Our car accident lawyers in Rosedale proudly serve Queens and the surrounding boroughs. We are committed to relieving our clients’ stress through handling their cases and accommodating to their personal needs.
                    </p>
                </div>
                <div className="img-container">
                    <img src={CarAccident} alt="" />
                </div>
            </div>
            {/* CALL ALEX */}
            <div className="call-alex">
                <p className='p'>
                    Call Alex Yadgarov & Associates for a free consultation at <a href="tel:718 276 2800">718 276 2800</a> . Se habla español.
                </p>
            </div>
            {/* REPRESENT */}
            <div className="represent">
                <div className="list-container">
                    <p className="p">
                        Our attorneys can help you get compensated for:
                    </p>
                    <ol>
                        <li>Pain sustained from injuries</li>
                        <li>Medical expenses</li>
                        <li>Property damage</li>
                        <li>Lost wages</li>
                        <li>Additional expenses such as rental cars and parking tickets</li>
                    </ol>
                </div>
                <div className="list-container">
                    <p className="p">
                        Injuries sustained from car accidents can be both physical and mental, and can include:
                    </p>
                    <div className="list-grid">
                        <ul>
                            <li>Whiplash</li>
                            <li>Internal bleeding</li>
                            <li>Post-traumatic stress disorder</li>
                        </ul>
                        <ul className='padding'>
                            <li>Head injuries</li>
                            <li>Broken bones</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* NO FAULT */}
            <div className="no-fault">
                <h3>
                    What is New York's "No Fault" Law?
                </h3>
                <div className="pad-2-text">
                    When it comes to New York state car accident laws, drivers must remember that in most circumstances injuries incurred as a result of a car accident must first be paid for out of the injured driver's own insurance policy. While a lawsuit for property damage may be filed directly against the responsible party following an accident, in order for a personal injury suit to be filed against the responsible driver, the injured party must be able to prove that their injuries are serious.
                </div>
            </div>
            {/* SERIOUS */}
            <div className="no-fault">
                <h3>
                    What is Considered a "Serious" Injury in New York?
                </h3>
                <div className="pad-2-text">
                    New York requires that in order for someone's injuries to qualify as "serious" and therefore allow for them to directly sue the responsible party, they must have experienced at least one of the following:
                </div>
                <ul>
                    <li>
                        Significant disfigurement
                    </li>
                    <li>
                        Bone fractures
                    </li>
                    <li>
                        Permanent limitation of use of a body organ or member
                    </li>
                    <li>
                        Significant limitation of use of a body function or system
                    </li>
                    <li>
                        Full disability for 90 days
                    </li>
                </ul>
            </div>
            {/* CLAIM */}
            <div className="no-fault">
                <h3>
                    How Long Do You Have to File a Claim?
                </h3>
                <div className="pad-2-text">
                    In most cases, the statute of limitations on car accident lawsuits in New York is three years. In other words, you have until the third anniversary of your collision to take legal action against those responsible for your accident. If a loved one died as a result of the collision, you have only two years from the date of death to file a lawsuit. If you attempt to file a claim outside of this window, the court will likely refuse to hear your claim and you will be unable to recover compensation. It is important to note that these deadlines only apply to lawsuits; insurance claims typically must be filed within a few days of the collision to be eligible for coverage.
                </div>
                <div className="pad-2-text">
                    While this may seem like a long time, the reality is that investigating a car accident can sometimes take months on it own before you are ready to file a lawsuit. For this reason, it is crucial you get an attorney involved as soon as possible to give yourself enough time to build a strong case within the filing deadline.
                </div>
            </div>
            {/* FREE CONSULTATION */}
            <div className="call-alex call-alex-2">
                <p className="p">
                    If you’re unsure whether you qualify to receive compensation for your accident injuries, <a href="tel:718 276 2800">call us for a free consultation</a>. We’ll travel to you so that you have one less thing to worry about. We proudly serve all of Queens, including Rochdale, Laurelton & Rosedale
                </p>
            </div>
        </article>
    </section>
  )
}
