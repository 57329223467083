import React from 'react'
import { Accordion } from 'react-bootstrap';
import { UnderlineSm } from '../Underlines/Underlines';
import './faq.less'
import faqDat from './faqDat';

export default() => {
  return (
    <section className="faq-car">
        <article className="container">
            <h1>
                Car Accident Frequently Asked Questions
            </h1>
            <UnderlineSm/>
            <Accordion defaultActiveKey="0" flush>
                {
                    faqDat.map((dat, idx)=>{
                        return(
                            <Accordion.Item eventKey={idx} key={idx+1}>
                                <Accordion.Header> <span>Q: </span>{dat.question}</Accordion.Header>
                                <Accordion.Body>
                                    <span>A: </span>{dat.answer}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })
                }
            </Accordion>
        </article>
    </section>
  )
}
