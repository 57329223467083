import React from "react"
import { Underlines } from "../Underlines/Underlines"
import AlexNatalia from "../../../images/alex&natalia.jpg"
import {GiCheckMark} from 'react-icons/gi'
import "./injury.less"

export default () => {
    return (
        <section className="injuries" id="team">
            <article className="container">
                <div className="u-hide">
                    <Underlines />
                </div>
                <div className="underline-injury">
                    <div className="underline-one"></div>
                    <div className="underline-two"></div>
                    <div className="underline-one"></div>
                </div>

                <div className="injury-grid">
                    <article className="injury-lawyers">
                        <img src={AlexNatalia} alt="Alex & Natalia" />
                        <p className="name-tag alex">Alex Yadgarov</p>
                        <p className="name-tag natalia">Natalia Derin</p>
                    </article>

                    <article className="injury-content">
                        <h2>New York City Car Accident Injury Lawyers</h2>
                        <h3>We Put You First</h3>
                        <p>
                            Since 2009, Alex Yadgarov & Associates has worked on Car Accident Injury cases for clients in Queens, Bronx, Brooklyn & Manhattan in the New York City area, from catastrophic car accidents to wrongful death claims. If you find yourself injured at the expense of a negligent party, you may be entitled to financial compensation. We fight for you till you get compensated.
                            <br />
                            <br />
                            Our law firm was awarded the “Best car accident lawyers” in Queens, New York. 
                        </p>
                    </article>
                </div>

                <div className="injury-point-container">
                    <div className="point-wrapper">
                        <ul>
                            <li><span><GiCheckMark/></span> Millions Won For Our Injury Clients</li>
                            <li><span><GiCheckMark/></span> Thousands Of Injury Cases Won</li>
                            <li><span><GiCheckMark/></span> Award-Winning Legal Team</li>
                            <li><span><GiCheckMark/></span> 100% NO Fee Unless You Win</li>
                            <li><span><GiCheckMark/></span> 5-Star Law Firm</li>
                        </ul>
                        <a href="tel:718-276-2800" className='btn'>
                            Get Your FREE Consultation Now 
                            <span>
                                718-276-2800
                            </span> 
                        </a>
                    </div>
                </div>        
            </article>

        </section>
    )
}
